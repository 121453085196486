// extracted by mini-css-extract-plugin
export var button__bvrhf = "PlasmicFooterSection-module--button__bvrhf--2wlxm";
export var button__eZJj = "PlasmicFooterSection-module--button__eZJj--PVdmS";
export var button__evmw7 = "PlasmicFooterSection-module--button__evmw7--sWkSz";
export var button__qg3Nr = "PlasmicFooterSection-module--button__qg3Nr---DM5J";
export var button__sZ2 = "PlasmicFooterSection-module--button__sZ2--5bg0l";
export var button__xhpm = "PlasmicFooterSection-module--button__xhpm--y3eiu";
export var button__xqAiY = "PlasmicFooterSection-module--button__xqAiY--9yVKx";
export var button__ypUEa = "PlasmicFooterSection-module--button__ypUEa--M9QrG";
export var button__zh8Ll = "PlasmicFooterSection-module--button__zh8Ll--fng-j";
export var column__onCom = "PlasmicFooterSection-module--column__onCom--fX1CB";
export var column__p7S97 = "PlasmicFooterSection-module--column__p7S97--uQnST";
export var column__xBmZ = "PlasmicFooterSection-module--column__xBmZ--E0sZN";
export var column__yLam2 = "PlasmicFooterSection-module--column__yLam2--wIaUe";
export var columns = "PlasmicFooterSection-module--columns--G4WSp";
export var freeBox__ajJyw = "PlasmicFooterSection-module--freeBox__ajJyw--AU9f5";
export var freeBox__d1QyU = "PlasmicFooterSection-module--freeBox__d1QyU--ajMvx";
export var freeBox__jrl4W = "PlasmicFooterSection-module--freeBox__jrl4W--psQt3";
export var freeBox__x4Kfs = "PlasmicFooterSection-module--freeBox__x4Kfs--pVsRY";
export var h4__ijeqf = "PlasmicFooterSection-module--h4__ijeqf--NefIH";
export var h4__nISkC = "PlasmicFooterSection-module--h4__nISkC--rNU5n";
export var h4__toWmb = "PlasmicFooterSection-module--h4__toWmb--AMgcq";
export var logo = "PlasmicFooterSection-module--logo--OcjEz";
export var root = "PlasmicFooterSection-module--root--F8od7";
export var svg___4Hw2I = "PlasmicFooterSection-module--svg___4Hw2I--oB2hI";
export var svg___6TmDu = "PlasmicFooterSection-module--svg___6TmDu--DzSmS";
export var svg___6WQcr = "PlasmicFooterSection-module--svg___6WQcr--bJWmd";
export var svg___7Pufo = "PlasmicFooterSection-module--svg___7Pufo--HzVzG";
export var svg___7W8CB = "PlasmicFooterSection-module--svg___7W8CB--2o4P-";
export var svg__b7D7 = "PlasmicFooterSection-module--svg__b7D7--llF8B";
export var svg__gjykr = "PlasmicFooterSection-module--svg__gjykr--hZAtE";
export var svg__gwNbH = "PlasmicFooterSection-module--svg__gwNbH--xS5qJ";
export var svg__jMzM8 = "PlasmicFooterSection-module--svg__jMzM8--VIs7f";
export var svg__kmRCx = "PlasmicFooterSection-module--svg__kmRCx--v3CNV";
export var svg__nWf2C = "PlasmicFooterSection-module--svg__nWf2C--z+cBA";
export var svg__ncVBm = "PlasmicFooterSection-module--svg__ncVBm--GHwYj";
export var svg__nwb8J = "PlasmicFooterSection-module--svg__nwb8J--RZKzd";
export var svg__tOBxb = "PlasmicFooterSection-module--svg__tOBxb--qYh4G";
export var svg__tPeAx = "PlasmicFooterSection-module--svg__tPeAx--pMSGZ";
export var svg__v9KbU = "PlasmicFooterSection-module--svg__v9KbU---uxPa";
export var svg__vwEf4 = "PlasmicFooterSection-module--svg__vwEf4--xf2Pq";
export var svg__w8UMc = "PlasmicFooterSection-module--svg__w8UMc--WT3Hi";
export var svg__xu0N4 = "PlasmicFooterSection-module--svg__xu0N4--6N++G";
export var svg__yVdRk = "PlasmicFooterSection-module--svg__yVdRk--9Z300";
export var svg__zFwJ7 = "PlasmicFooterSection-module--svg__zFwJ7--URLIW";
export var svg__zizLc = "PlasmicFooterSection-module--svg__zizLc--IHi0k";
export var text___5Nzb = "PlasmicFooterSection-module--text___5Nzb--oIAOz";
export var text__gqM4K = "PlasmicFooterSection-module--text__gqM4K--un2-h";
export var text__jnhx = "PlasmicFooterSection-module--text__jnhx--3YHwB";
export var text__jtVrP = "PlasmicFooterSection-module--text__jtVrP--Wnwhq";
export var text__stTw8 = "PlasmicFooterSection-module--text__stTw8--IOUW-";
export var text__tLem2 = "PlasmicFooterSection-module--text__tLem2--AqMkM";
export var text__utq8M = "PlasmicFooterSection-module--text__utq8M--LR1jf";
export var text__wgsi = "PlasmicFooterSection-module--text__wgsi--W2WJU";
export var text__wnXg = "PlasmicFooterSection-module--text__wnXg--nMONy";
export var text__wnZDu = "PlasmicFooterSection-module--text__wnZDu--SZA5l";
export var text__ymNbD = "PlasmicFooterSection-module--text__ymNbD--smfwt";