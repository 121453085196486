// extracted by mini-css-extract-plugin
export var freeBox__aNbMy = "PlasmicPriceSection-module--freeBox__aNbMy--uGG66";
export var freeBox__qgod = "PlasmicPriceSection-module--freeBox__qgod--Ubtig";
export var freeBox__sFeci = "PlasmicPriceSection-module--freeBox__sFeci--Gey3t";
export var freeBox__tNUdP = "PlasmicPriceSection-module--freeBox__tNUdP--yIgrr";
export var freeBox__yXiQn = "PlasmicPriceSection-module--freeBox__yXiQn--KVFnc";
export var priceCard__an2Ft = "PlasmicPriceSection-module--priceCard__an2Ft--JLiWS";
export var priceCard__rvDjZ = "PlasmicPriceSection-module--priceCard__rvDjZ--+3iNc";
export var priceCard__veJin = "PlasmicPriceSection-module--priceCard__veJin--YnwbB";
export var root = "PlasmicPriceSection-module--root--DJN7Y";
export var text = "PlasmicPriceSection-module--text--rqNfN";