// extracted by mini-css-extract-plugin
export var freeBox = "PlasmicSection-module--freeBox--E8uFe";
export var freeBoxsize_fullContentGallery = "PlasmicSection-module--freeBoxsize_fullContentGallery--7rMY7";
export var freeBoxsize_fullContentWidth = "PlasmicSection-module--freeBoxsize_fullContentWidth--soNx-";
export var freeBoxsize_mobile = "PlasmicSection-module--freeBoxsize_mobile--9ZUWT";
export var root = "PlasmicSection-module--root--KLU7P";
export var rootbackground_dark = "PlasmicSection-module--rootbackground_dark--kL84V";
export var rootbackground_gray = "PlasmicSection-module--rootbackground_gray--Efrxt";
export var rootsize_fullContentGallery = "PlasmicSection-module--rootsize_fullContentGallery--j-lkT";
export var rootsize_fullContentWidth = "PlasmicSection-module--rootsize_fullContentWidth--UR0T3";
export var rootsize_mobile = "PlasmicSection-module--rootsize_mobile--boyxn";