// extracted by mini-css-extract-plugin
export var button = "PlasmicPriceCard-module--button--NU2lK";
export var buttonborders_flatCenter = "PlasmicPriceCard-module--buttonborders_flatCenter--4wVrH";
export var buttonborders_flatLeft = "PlasmicPriceCard-module--buttonborders_flatLeft--Vg5zI";
export var buttonborders_flatRight = "PlasmicPriceCard-module--buttonborders_flatRight--JgRY1";
export var buttonprimary = "PlasmicPriceCard-module--buttonprimary--NdH3Y";
export var column___8DWUq = "PlasmicPriceCard-module--column___8DWUq--TRuq5";
export var column___9LnUm = "PlasmicPriceCard-module--column___9LnUm--0RZv5";
export var column__hBef = "PlasmicPriceCard-module--column__hBef--HpfqK";
export var column__hnvfB = "PlasmicPriceCard-module--column__hnvfB--KeuEA";
export var column__hycNs = "PlasmicPriceCard-module--column__hycNs--1Nm7X";
export var column__ikUeb = "PlasmicPriceCard-module--column__ikUeb--gAgeP";
export var column__ilWbw = "PlasmicPriceCard-module--column__ilWbw--yvSVt";
export var column__jdmEm = "PlasmicPriceCard-module--column__jdmEm--7wUCX";
export var column__krNv = "PlasmicPriceCard-module--column__krNv--fI+Ix";
export var column__l9ZoM = "PlasmicPriceCard-module--column__l9ZoM--vOFkj";
export var column__lCdHl = "PlasmicPriceCard-module--column__lCdHl--xaNpD";
export var column__ozXvb = "PlasmicPriceCard-module--column__ozXvb--jsxMg";
export var column__p2H7U = "PlasmicPriceCard-module--column__p2H7U--m9Nfd";
export var column__pal1C = "PlasmicPriceCard-module--column__pal1C--u7yW8";
export var column__sGo4A = "PlasmicPriceCard-module--column__sGo4A--SFGzU";
export var column__waxNe = "PlasmicPriceCard-module--column__waxNe--xp-NZ";
export var column__wiw3D = "PlasmicPriceCard-module--column__wiw3D--vf7Ni";
export var columnborders_flatLeft__lCdHLveVp7 = "PlasmicPriceCard-module--columnborders_flatLeft__lCdHLveVp7--ZYdI3";
export var columnborders_flatRight__lCdHlGiJNd = "PlasmicPriceCard-module--columnborders_flatRight__lCdHlGiJNd--Av8BO";
export var columnprimary__lCdHloaL2 = "PlasmicPriceCard-module--columnprimary__lCdHloaL2--y8PK+";
export var columns__ciZke = "PlasmicPriceCard-module--columns__ciZke--B2m+3";
export var columns__cp2W4 = "PlasmicPriceCard-module--columns__cp2W4--yczJg";
export var columns__iJlw = "PlasmicPriceCard-module--columns__iJlw--P0aSi";
export var columns__kgQnW = "PlasmicPriceCard-module--columns__kgQnW--Rfx4v";
export var columns__oQdy1 = "PlasmicPriceCard-module--columns__oQdy1--Yb3ZA";
export var columns__vNnFk = "PlasmicPriceCard-module--columns__vNnFk--Zgj0K";
export var columns__wAsxy = "PlasmicPriceCard-module--columns__wAsxy--yxUpF";
export var columns__weV1O = "PlasmicPriceCard-module--columns__weV1O--T9w0S";
export var columnsborders_flatCenter__ciZke2Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__ciZke2Kv6--wS+aM";
export var columnsborders_flatCenter__cp2W42Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__cp2W42Kv6--V0Mqq";
export var columnsborders_flatCenter__kgQnW2Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__kgQnW2Kv6--P3Vbm";
export var columnsborders_flatCenter__oQdy12Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__oQdy12Kv6--YchkP";
export var columnsborders_flatCenter__vNnFk2Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__vNnFk2Kv6--dn3YL";
export var columnsborders_flatCenter__wAsxy2Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__wAsxy2Kv6--kdyaP";
export var columnsborders_flatCenter__weV1O2Kv6 = "PlasmicPriceCard-module--columnsborders_flatCenter__weV1O2Kv6--I8mm2";
export var columnsborders_flatLeft__cp2W4VeVp7 = "PlasmicPriceCard-module--columnsborders_flatLeft__cp2W4VeVp7--OAA1H";
export var columnsborders_flatLeft__oQdy1VeVp7 = "PlasmicPriceCard-module--columnsborders_flatLeft__oQdy1VeVp7--e8qoj";
export var columnsborders_flatLeft__wAsxyveVp7 = "PlasmicPriceCard-module--columnsborders_flatLeft__wAsxyveVp7--HLIzq";
export var columnsborders_flatRight__oQdy1GiJNd = "PlasmicPriceCard-module--columnsborders_flatRight__oQdy1GiJNd--A926H";
export var columnsprimary__ciZkeOaL2 = "PlasmicPriceCard-module--columnsprimary__ciZkeOaL2--dJhbz";
export var columnsprimary__cp2W4OaL2 = "PlasmicPriceCard-module--columnsprimary__cp2W4OaL2--I0VwB";
export var columnsprimary__iJlwOaL2 = "PlasmicPriceCard-module--columnsprimary__iJlwOaL2--pDBBp";
export var columnsprimary__kgQnWoaL2 = "PlasmicPriceCard-module--columnsprimary__kgQnWoaL2--Mf2HK";
export var columnsprimary__oQdy1OaL2 = "PlasmicPriceCard-module--columnsprimary__oQdy1OaL2--NHTvb";
export var columnsprimary__vNnFkOaL2 = "PlasmicPriceCard-module--columnsprimary__vNnFkOaL2--G72My";
export var columnsprimary__wAsxyOaL2 = "PlasmicPriceCard-module--columnsprimary__wAsxyOaL2--rkuLt";
export var columnsprimary__weV1OOaL2 = "PlasmicPriceCard-module--columnsprimary__weV1OOaL2--cvZJz";
export var freeBox = "PlasmicPriceCard-module--freeBox--BlhVz";
export var freeBoxborders_flatLeft = "PlasmicPriceCard-module--freeBoxborders_flatLeft--czfOb";
export var freeBoxborders_flatRight = "PlasmicPriceCard-module--freeBoxborders_flatRight--XgNwv";
export var h4 = "PlasmicPriceCard-module--h4--xl5DY";
export var h4borders_flatCenter = "PlasmicPriceCard-module--h4borders_flatCenter--sn6oQ";
export var h4borders_flatLeft = "PlasmicPriceCard-module--h4borders_flatLeft--XNOAx";
export var h4borders_flatRight = "PlasmicPriceCard-module--h4borders_flatRight---RbI9";
export var img___3IIxx = "PlasmicPriceCard-module--img___3IIxx--DzQvK";
export var img___4NApc = "PlasmicPriceCard-module--img___4NApc--+LgWA";
export var img___5PLin = "PlasmicPriceCard-module--img___5PLin--JSRTZ";
export var img___8Qbsr = "PlasmicPriceCard-module--img___8Qbsr--JwkA1";
export var img__hb9Q = "PlasmicPriceCard-module--img__hb9Q--xO6cX";
export var img__nu6Bl = "PlasmicPriceCard-module--img__nu6Bl--FrBe7";
export var img__oPy0H = "PlasmicPriceCard-module--img__oPy0H--MjC-n";
export var img__vothJ = "PlasmicPriceCard-module--img__vothJ--Ba3EK";
export var img__wshfg = "PlasmicPriceCard-module--img__wshfg--41woX";
export var img__xb0B9 = "PlasmicPriceCard-module--img__xb0B9--nhG5M";
export var root = "PlasmicPriceCard-module--root--yjE6l";
export var rootborders_flatCenter = "PlasmicPriceCard-module--rootborders_flatCenter--+BMU6";
export var rootborders_flatLeft = "PlasmicPriceCard-module--rootborders_flatLeft--YKLsa";
export var rootborders_flatRight = "PlasmicPriceCard-module--rootborders_flatRight--z2MTD";
export var rootprimary = "PlasmicPriceCard-module--rootprimary--dudPr";
export var svg__fJ5Hg = "PlasmicPriceCard-module--svg__fJ5Hg--Vk8cA";
export var svg__jrWKn = "PlasmicPriceCard-module--svg__jrWKn--xkzK9";
export var text___0S1A = "PlasmicPriceCard-module--text___0S1A--5capm";
export var text___5LsSy = "PlasmicPriceCard-module--text___5LsSy--IQfj+";
export var text__aVfgX = "PlasmicPriceCard-module--text__aVfgX--5mmwr";
export var text__cwYoQ = "PlasmicPriceCard-module--text__cwYoQ--Z1Xo7";
export var text__erhOc = "PlasmicPriceCard-module--text__erhOc--LAKIr";
export var text__fcCSh = "PlasmicPriceCard-module--text__fcCSh--CeKF+";
export var text__ieC7N = "PlasmicPriceCard-module--text__ieC7N--vrKrG";
export var text__iwBjH = "PlasmicPriceCard-module--text__iwBjH--0hsbW";
export var text__l6KF7 = "PlasmicPriceCard-module--text__l6KF7--vTAwM";
export var text__lDZvH = "PlasmicPriceCard-module--text__lDZvH--v74Gc";
export var text__rAg26 = "PlasmicPriceCard-module--text__rAg26--OxiV2";
export var text__rHlhe = "PlasmicPriceCard-module--text__rHlhe--g49H6";
export var textborders_flatCenter___5LsSy2Kv6 = "PlasmicPriceCard-module--textborders_flatCenter___5LsSy2Kv6--5jSrI";
export var textborders_flatCenter__cwYoQ2Kv6 = "PlasmicPriceCard-module--textborders_flatCenter__cwYoQ2Kv6--dLHzo";
export var textborders_flatCenter__rAg262Kv6 = "PlasmicPriceCard-module--textborders_flatCenter__rAg262Kv6--rjAZ1";
export var textborders_flatLeft___5LsSYveVp7 = "PlasmicPriceCard-module--textborders_flatLeft___5LsSYveVp7--lwlHy";
export var textborders_flatLeft__cwYoQveVp7 = "PlasmicPriceCard-module--textborders_flatLeft__cwYoQveVp7--DP3Cy";
export var textborders_flatLeft__erhOCveVp7 = "PlasmicPriceCard-module--textborders_flatLeft__erhOCveVp7---YD00";
export var textborders_flatLeft__ieC7NveVp7 = "PlasmicPriceCard-module--textborders_flatLeft__ieC7NveVp7--Pv5kX";
export var textborders_flatLeft__iwBjHveVp7 = "PlasmicPriceCard-module--textborders_flatLeft__iwBjHveVp7--fA9nC";
export var textborders_flatLeft__lDZvHveVp7 = "PlasmicPriceCard-module--textborders_flatLeft__lDZvHveVp7--Z5g90";
export var textborders_flatLeft__rAg26VeVp7 = "PlasmicPriceCard-module--textborders_flatLeft__rAg26VeVp7--M56rg";
export var textborders_flatLeft__rHlhEveVp7 = "PlasmicPriceCard-module--textborders_flatLeft__rHlhEveVp7--dIQFZ";
export var textborders_flatRight___5LsSyGiJNd = "PlasmicPriceCard-module--textborders_flatRight___5LsSyGiJNd--5mKWi";
export var textborders_flatRight__cwYoQGiJNd = "PlasmicPriceCard-module--textborders_flatRight__cwYoQGiJNd--ienOv";
export var textborders_flatRight__erhOcGiJNd = "PlasmicPriceCard-module--textborders_flatRight__erhOcGiJNd--FnEL7";
export var textborders_flatRight__ieC7NGiJNd = "PlasmicPriceCard-module--textborders_flatRight__ieC7NGiJNd--TFLqF";
export var textborders_flatRight__rAg26GiJNd = "PlasmicPriceCard-module--textborders_flatRight__rAg26GiJNd--myF5j";
export var textborders_flatRight__rHlheGiJNd = "PlasmicPriceCard-module--textborders_flatRight__rHlheGiJNd--ug0MZ";
export var textprimary__rAg26OaL2 = "PlasmicPriceCard-module--textprimary__rAg26OaL2--QzuaS";