// extracted by mini-css-extract-plugin
export var root = "PlasmicButton-module--root--2hT-O";
export var rootcolor_blue = "PlasmicButton-module--rootcolor_blue--pcEsE";
export var rootcolor_blueBorderSmall = "PlasmicButton-module--rootcolor_blueBorderSmall--QfA4G";
export var rootcolor_blueSmall = "PlasmicButton-module--rootcolor_blueSmall--47smC";
export var rootcolor_darkGray = "PlasmicButton-module--rootcolor_darkGray--udVbV";
export var rootcolor_footerButton = "PlasmicButton-module--rootcolor_footerButton--mGI0b";
export var rootcolor_green = "PlasmicButton-module--rootcolor_green--VYZU5";
export var rootcolor_indigo = "PlasmicButton-module--rootcolor_indigo--SC62G";
export var rootcolor_navLink = "PlasmicButton-module--rootcolor_navLink--OKDIh";
export var rootcolor_navLinkBlue = "PlasmicButton-module--rootcolor_navLinkBlue--7oXmT";
export var rootcolor_red = "PlasmicButton-module--rootcolor_red--RtDEy";
export var rootcolor_white = "PlasmicButton-module--rootcolor_white--1Bs3z";
export var rooticonOnly = "PlasmicButton-module--rooticonOnly--R08kJ";
export var rootisDisabled = "PlasmicButton-module--rootisDisabled--pZuXi";
export var rootshowStartIcon = "PlasmicButton-module--rootshowStartIcon--Jqqcp";
export var slotTargetChildren = "PlasmicButton-module--slotTargetChildren--CLgPO";
export var slotTargetChildrencolor_blue = "PlasmicButton-module--slotTargetChildrencolor_blue--LHiUF";
export var slotTargetChildrencolor_darkGray = "PlasmicButton-module--slotTargetChildrencolor_darkGray--DiccB";
export var slotTargetChildrencolor_green = "PlasmicButton-module--slotTargetChildrencolor_green--ASVvB";
export var slotTargetChildrencolor_indigo = "PlasmicButton-module--slotTargetChildrencolor_indigo--184sv";
export var slotTargetChildrencolor_link = "PlasmicButton-module--slotTargetChildrencolor_link--tII46";
export var slotTargetChildrencolor_navLink = "PlasmicButton-module--slotTargetChildrencolor_navLink--KoTX5";
export var slotTargetChildrencolor_red = "PlasmicButton-module--slotTargetChildrencolor_red--BGS0f";
export var slotTargetChildrencolor_white = "PlasmicButton-module--slotTargetChildrencolor_white--pHo9O";
export var slotTargetChildrenlarge = "PlasmicButton-module--slotTargetChildrenlarge--dthGn";
export var slotTargetEndIcon = "PlasmicButton-module--slotTargetEndIcon--ODTrx";
export var slotTargetEndIconcolor_blue = "PlasmicButton-module--slotTargetEndIconcolor_blue--Cn9LQ";
export var slotTargetEndIconcolor_darkGray = "PlasmicButton-module--slotTargetEndIconcolor_darkGray--J7bVm";
export var slotTargetEndIconcolor_green = "PlasmicButton-module--slotTargetEndIconcolor_green--3U6C9";
export var slotTargetEndIconcolor_indigo = "PlasmicButton-module--slotTargetEndIconcolor_indigo--UWnM1";
export var slotTargetEndIconcolor_link = "PlasmicButton-module--slotTargetEndIconcolor_link--qQVpc";
export var slotTargetEndIconcolor_red = "PlasmicButton-module--slotTargetEndIconcolor_red--Jz6lt";
export var slotTargetEndIconcolor_white = "PlasmicButton-module--slotTargetEndIconcolor_white--Svbpu";
export var slotTargetStartIcon = "PlasmicButton-module--slotTargetStartIcon--hcN6v";
export var slotTargetStartIconcolor_blue = "PlasmicButton-module--slotTargetStartIconcolor_blue--lLktW";
export var slotTargetStartIconcolor_darkGray = "PlasmicButton-module--slotTargetStartIconcolor_darkGray---Xp8C";
export var slotTargetStartIconcolor_green = "PlasmicButton-module--slotTargetStartIconcolor_green--y7aYk";
export var slotTargetStartIconcolor_indigo = "PlasmicButton-module--slotTargetStartIconcolor_indigo--GP6gB";
export var slotTargetStartIconcolor_link = "PlasmicButton-module--slotTargetStartIconcolor_link--35HK2";
export var slotTargetStartIconcolor_red = "PlasmicButton-module--slotTargetStartIconcolor_red--3bA2w";
export var slotTargetStartIconcolor_white = "PlasmicButton-module--slotTargetStartIconcolor_white--Bn3In";
export var svg__butXx = "PlasmicButton-module--svg__butXx--5LXRg";
export var svg__dAzWm = "PlasmicButton-module--svg__dAzWm--XUSYN";